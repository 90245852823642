<template>
<div>
  <ly-formList :fromList="fromList" @getVal="getVal" @cancel="cancel"></ly-formList>
</div>
</template>

<script setup>
import { ref } from 'vue'
import {  phoneValidate, idNumberValidate } from '@/utils/validate'
const emit = defineEmits(['getVal'])
const getVal = (e) => {
  emit('getVal',e)
}
const cancel = () => {
  emit('cancel')
}
const fromList = ref([
  {
    label: '业务对接人姓名',
    name: 'dockingPerson',
    type: 'input',
    placeholder: '请输入对接人姓名',
    value: null,
    prop: 'dockingPerson',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入对接人姓名',
        trigger: 'blur'
      },
    ]
  },
  {
    label: '业务对接人电话',
    name: 'dockingMobile',
    type: 'input',
    placeholder: '请输入对接人电话',
    value: null,
    prop: 'dockingMobile',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入对接人电话',
        trigger: 'blur'
      },
      { pattern: phoneValidate, message: '号码格式不正确', trigger: 'blur' }
    ]
  },
  {
    label: '身份证号',
    name: 'idNumber',
    type: 'input',
    placeholder: '请输入身份证号',
    value: null,
    prop: 'idNumber',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入身份证号',
        trigger: 'blur'
      },
      { pattern: idNumberValidate, message: '证件号码格式不正确', trigger: 'blur' }
    ]
  },
  {
    label: '备注',
    name: 'remark',
    type: 'textarea',
    placeholder: '请输入备注',
    value: null,
    prop: 'remark',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入备注',
        trigger: 'blur'
      },
    ]
  },
])
</script>

<style lang="less" scoped>

</style>